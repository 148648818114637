import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";

let tags = [
  { name: "الرئيسية", link: "/" },
  { name: "نبذة عن الباحث", link: "/aboutUs" },
  { name: "الكتب", link: "/books" },
  { name: "المقالات", link: "/blogs" },
  { name: "تواصل معنا", link: "/contact" },
];

function NavBar() {
  const { pathname } = useLocation();
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="white"
        variant="white"
        className="border-bottom position-fixed top-0 left-0 right-0 w-100"
        style={{ zIndex: "99" }}
      >
        <Container>
          <Navbar.Brand
            className="m-0 font2 my-3 w-50 logoText  text-danger"
            as={Link}
            to="/"
          >
            د.إسماعيل السلامات باحث في علم النسب العربي والأنثروبولوجيا
            السُعودية
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto font1">
              {tags.map((item) => {
                return (
                  <Nav.Link
                    className="fw-bolder fs-7"
                    key={item.link}
                    style={pathname === item.link ? { color: "#ff850e" } : null}
                    active={item.link === pathname}
                    as={Link}
                    to={item.link}
                  >
                    {item.name}
                  </Nav.Link>
                );
              })}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <div className="nav-h"></div>
    </>
  );
}

export default NavBar;
