import React, { useEffect } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";

function FeaturedSection({ data }) {
  useEffect(() => {
    AOS.init({ duration: 1700 });
  });
  return (
    <Container className="bg-light position-relative mb-150">
      <Row className="pt-5">
        <Col
          xs="12"
          lg="6"
          className="text-center order-lg-3"
          data-aos="fade-left"
        >
          <img
            className="mx-auto mw-100  mt-n10"
            width="400px"
            src={data.imgSrc}
            alt="book img"
          />
        </Col>
        <Col
          xs="12"
          lg="6"
          className="text-center text-lg-end "
          data-aos="fade-down"
        >
          <h1 className="fs-3 fw-bold font2 ">{data.title}</h1>
          <p className="fs-4 mt-4 mb-0">{data.summary}</p>
          <Button
            as={Link}
            to={`book/${data.id}`}
            className="bg-danger px-4 py-3 fw-bold my-5 rounded-0 border-0 "
          >
            إطلع على الكتاب
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default FeaturedSection;
