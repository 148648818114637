import React from "react";
import AboutOfSection from "../components/AboutOfSection/AboutOfSection";
import BlogsSection from "../components/BlogsSection/BlogsSection";
import BooksSection from "../components/BooksSection/BooksSection";
import ContactUsSection from "../components/ContactUsSection/ContactUsSection";
import FeaturedSection from "../components/FeaturedSection/FeaturedSection";


function HomePage({ booksData, blogsData }) {
  let data = [...booksData].reverse();

  return (
    <div>
      <img src="https://i.ibb.co/RNb397Z/15.jpg" alt="img" className="w-100" />
      <AboutOfSection booksData={booksData.slice(0, 3)} />
      <BooksSection booksData={booksData} />
      {data.map((item) => (
        <FeaturedSection data={item} key={item.id} />
      ))}
      <BlogsSection blogsData={blogsData} />
      <ContactUsSection />
    </div>
  );
}

export default HomePage;
