import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

function BookCard({ bookData }) {
  useEffect(() => {
    AOS.init({ duration: 1700 });
  });
  return (
    <Link
      data-aos="zoom-in-down"
      to={`/book/${bookData.id}`}
      className="w-100 text-decoration-none"
    >
      <img width="100%" src={`${bookData.coverImg}`} alt="book imag" />
      <h5 className="my-3 fw-semibold fs-5 text-black fs-4">
        {bookData.title}
      </h5>
    </Link>
  );
}

export default BookCard;
