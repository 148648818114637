import {
  END_PROCCESS_BOOK,
  SET_ALL_BOOKS,
  GET_DATA_FAIL_BOOK,
  START_PROCCESS_BOOK,
} from "../constant/booksConstant";

export default function bookReducer(
  state = { booksData: [], errorMessage: "", bookProccess: false },
  action
) {
  switch (action.type) {
    case START_PROCCESS_BOOK:
      return { ...state, bookProccess: true };
    case END_PROCCESS_BOOK:
      return { ...state, bookProccess: false };
    case SET_ALL_BOOKS:
      return { ...state, booksData: action.payload };
    case GET_DATA_FAIL_BOOK:
      return { ...state, errorMessage: action.payload };
    default:
      return state;
  }
}
