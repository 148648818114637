import React from "react";
import { Link } from "react-router-dom";

let tags = [
  { name: "الرئيسية", link: "/" },
  { name: "نبذة عن الباحث", link: "/aboutUs" },
  { name: "المقالات", link: "/blogs" },
  { name: "تواصل معنا", link: "/contact" },
];

function Footer() {
  return (
    <footer className="text-center text-lg-start bg-light text-muted fs-7 py-3">
      <section className="">
        <div className="container text-center text-md-start mt-5">
          <div className="row mt-3 justify-content-between">
            <div className="col-md-6  col-xl-4 mb-4 text-end p-xl-0 ps-3 ">
              <h6 className="text-uppercase fw-bold mb-2 fs-4 text-black mb-3">
                د.إسماعيل السلامات باحث في علم النسب العربي والأنثروبولوجيا
                السُعودية
              </h6>
              <p>
                تمرُّ المُجتمعاتُ العربيَّةُ الراهنة بنقلةٍ نوعيةٍ مِن التطوراتِ
                في بُنيتها المُجتمعيّة، ويأتي الُمجتمع العربي السُّعودي في
                طليعتها وفق التطورات السريعة والعميقة التي يمرُّ بها في ظل
                إستراتيجية خطط التنمية المُستدامة لرؤيةِ (2030) السُّعوديّة
                برعايةِ خادم الحرمين الشّريفين الملك سلمان بن عبد العزيز آل سعود
                ، وولي عهده الأمين صاحب السمو الملكي الأمير محمد بن سلمان آل
                سعود حفظهما الله.
              </p>
            </div>

            <div className="col-md-6 col-xl-2  mb-4 text-end">
              <h5 className="text-uppercase fw-bold mb-3 text-black">
                روابط مهمة
              </h5>
              <div className="column">
                {tags.map((item) => {
                  return (
                    <Link
                      className="text-decoration-none text-reset my-2 d-block"
                      key={item.link}
                      to={item.link}
                    >
                      {item.name}
                    </Link>
                  );
                })}
              </div>
            </div>

            <div className="col-md-6 col-xl-2  mb-md-0 mb-4 text-end">
              <h5 className="text-uppercase fw-bold mb-3 text-black">
                معلومات الإتصال
              </h5>
              <p>
                <i className="fa-solid fa-location-dot fs-6 ms-2"></i>المملكة
                العربية السعودية
              </p>
              <p>
                <i className="fa-solid fa-phone fs-6 ms-2"></i>٩٦٦٥٦٤٦٤٤٧٤٣+
              </p>
              <p>
                <i className="fas fa-envelope fs-6 ms-2"></i>
                dr.asmaeel@anthropologist-dr-alsalamat.com
              </p>
            </div>
            <div className="col-md-6 col-xl-3  mb-md-0 mb-4 text-end text-dark">
              <h5 className="text-uppercase fw-bold mb-3 text-black">
                تواصل معنا
              </h5>

              {/* <Link to="/" className="ms-3 .text-primary fs-6">
                <i className="fab fa-facebook-f "></i>
              </Link>
              <Link to="/" className="ms-3 text-danger fs-6">
                <i className="fab fa-instagram "></i>
              </Link> */}
              <a
                href="https://twitter.com/9afedd964et8leX"
                className="ms-3 text-info fs-6"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
}

export default Footer;
