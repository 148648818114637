import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import BlogCard from "../BlogCard/BlogCard";
import AOS from "aos";
import "aos/dist/aos.css";
function BlogsSection({ blogsData }) {
  let [arr, setItems] = useState([]);
  // console.log(arr , 'll');
  useEffect(() => {
    let newArr = [
      [...blogsData].find((element) => element.title.includes("الشرق")),
      [...blogsData].find((element) => element.title.includes("أصالة التاريخ")),
    ];
    setItems(newArr);
    AOS.init({ duration: 1700 });
  }, [blogsData]);
  return (
    <Container className="py-5 text-center text-lg-end">
      <Row>
        <Col
          xs="12"
          lg="4"
          className="p-lg-5 p-3 pe-lg-0 "
          data-aos="flip-right"
        >
          <p className="text-danger m-0 mb-2 font2">المقالات </p>
          <h1 className="fw-bold font2"> أحدث المقالات</h1>
          <p>تصفح أحدث مقالات د.إسماعيل بن محمد السلامات</p>
          <Link to="blogs">
            <button className="btn btn-danger px-5 py-3 rounded-0 ">
              اطلع على المزيد
              <i className="fa-solid fa-arrow-left-long pe-2"></i>
            </button>
          </Link>
        </Col>
        {console.log(arr, "pp")}
        {arr[0]
          ? arr.map((item) => (
              <Col xs="12" lg="4" className="p-3" key={item.id}>
                {console.log(arr)}
                <BlogCard blogData={item} />
              </Col>
            ))
          : console.log(arr, "false")}
      </Row>
    </Container>
  );
}

export default BlogsSection;
