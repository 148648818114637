import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import BookCard from "../BookCard/BookCard";
import AOS from "aos";
import "aos/dist/aos.css";

function BooksSection({ booksData }) {
  useEffect(() => {
    AOS.init({ duration: 1700 });
  });
  let data = [...booksData].reverse();

  return (
    <section className="text-center container my-5 py-5" data-aos="fade-up">
      <h2 className=" pb-4 fw-bold font2">من مكتبة د.اسماعيل السلامات</h2>
      {/* <p className="my-4"></p> */}
      <Row className="justify-content-evenly">
        {data.map((item) => {
          return (
            <Col xs={6} md={6} lg={4} xl={3} className="p-3" key={item.id}>
              <BookCard bookData={item} />
            </Col>
          );
        })}
      </Row>
    </section>
  );
}

export default BooksSection;
